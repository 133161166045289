@import "bootstrap/scss/functions";
@import "abstract/bs-variables";
@import "bootstrap/scss/utilities";




$utilities: map-merge($utilities,
        ( // Add new utilities
            "object-position": (property: object-position,
                class: object-position,
                responsive: true,
                values: top left right center bottom,
            ),

            // Edit utilities
            "position": map-merge(map-get($utilities, "position"),
                (responsive: true)),
            "color": map-merge(map-get($utilities, "color"),
                (responsive: true,
                    values: map-merge(map-get(map-get($utilities, "color"), "values"),
                        ("gray-100": $gray-100,
                            "gray-200": $gray-200,
                            "gray-300": $gray-300,
                            "gray-400": $gray-400,
                            "gray-500": $gray-500,
                            "gray-600": $gray-600,
                            "gray-700": $gray-700,
                            "gray-800": $gray-800,
                            "gray-900": $gray-900,
                            "cyan-100": $cyan-100,
                            "cyan-600": $cyan-600,
                            "blue-100": $blue-100,
                            "teal-400": $teal-400,
                        ),
                    ),
                ),
            ),
            "border-color": map-merge(map-get($utilities, "border-color"),
                (responsive: true,
                    values: map-merge(map-get(map-get($utilities, "border-color"), "values"),
                        ("gray-100": $gray-100,
                            "gray-200": $gray-200,
                            "gray-300": $gray-300,
                            "gray-400": $gray-400,
                            "gray-500": $gray-500,
                            "gray-600": $gray-600,
                            "gray-700": $gray-700,
                            "gray-800": $gray-800,
                            "gray-900": $gray-900,
                            "indigo": $indigo,
                        ),
                    ),
                ),
            ),
            // "border-bottom": map-merge(
            //     map-get($utilities, "border-bottom"),
            //     (
            //         values: map-merge(
            //             map-get(map-get($utilities, "border-bottom"), "values"),
            //             (
            //                1:map-get($border-widths, 1),
            //                2:map-get($border-widths, 2),
            //                3:map-get($border-widths, 3),
            //                4:map-get($border-widths, 4)
            //             ),
            //         ),
            //     ),
            // ),

            "background-color": map-merge(map-get($utilities, "background-color"),
                (responsive: true,
                    values: map-merge(map-get(map-get($utilities, "background-color"), "values"),
                        ("gray-100": $gray-100,
                            "gray-200": $gray-200,
                            "gray-300": $gray-300,
                            "gray-400": $gray-400,
                            "gray-500": $gray-500,
                            "gray-600": $gray-600,
                            "gray-700": $gray-700,
                            "gray-800": $gray-800,
                            "gray-900": $gray-900,
                            "cyan": $cyan-600,
                            "cyan-100": tint-color($cyan, 95%),
                            "cyan-light": tint-color($cyan, 90%),
                            "cyan-200": $cyan-200,
                            "blue-100": tint-color($blue, 95%),
                            "indigo": $indigo,
                            "indigo-100": tint-color($indigo, 95%),
                            "blue-light": tint-color($blue, 95%),
                        ),
                    ),
                ),
            ),
            "border-style": (property: border-style,
                class: border,
                values: dashed dotted,
            ),
            "link-underline-thickness": (css-var: false,
                class: link-underline-thickness,
                property: text-decoration-thickness,
                state: hover,
                values: (0: 0,
                    1: 1px,
                    2: 2px,
                ),
            ),
            "gap-y": (responsive: true,
                class: gap-y,
                property: row-gap,
                values:$spacers ),
            "gap-x": (responsive: true,
                class: gap-x,
                property: column-gap,
                values:$spacers ),

            // "link": (
            //     class: link,
            //     property: text-decoration,
            //     state: hover,
            //     values: underline
            //     ),

        ));


// @include debug-map($utilities);



// .min-h-screen {
//     min-height: calc(100vh - $header-height);
// }


// %link-underline-decoration {
//     text-decoration: none;
//     background: linear-gradient(to right, transparent, transparent), linear-gradient(to right, currentColor,  currentColor);
//     background-size: 100% 1px, 0 1px;    background-position: 100% 100%, 0 100%;
//     background-repeat: no-repeat;
//     transition: background-size 300ms;
//   }

// //   &:hover {
// //     background-size: 0 1px, 100% 1px;

// // }



// a.link-decoration-underline {
//     @extend %link-underline-decoration;
//     &:hover {
//         background-size: 0 1px, 100% 1px;
//     }
//   }











// @each $color-key, $color-value in $grays {
//     .text-gray-#{$color-key} {
//         color: #{$color-value} !important;
//     }
//     .border-gray-#{$color-key} {
//         // border-color: #{$color-value} !important;
//         border-color: rgba(var(--#{$prefix}gray-#{$color-key}), 1) !important;
//     }
//     .bg-gray-#{$color-key} {
//         background-color: #{$color-value} !important;
//     }  
// }




// font sizes 
.fs {
    &-small {
        font-size: $small-font-size !important;
    }

    &-normal {
        font-size: $font-size-base !important;
    }
}

.link {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &-inverse {
        color: inherit;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}


.min-vh-header {
    min-height: calc(100vh - 130px);

    @include media-breakpoint-up('xl') {
        min-height: calc(100vh - 152px);
    }
}

.max-wh {
    &-md {
        max-height: 2rem;
        max-width: 2rem;
    }
}


.link-animate {
    transition: color .15s, text-decoration-color .15s;
}
