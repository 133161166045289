.avatar {
    height: 2rem;
    width: 2rem;
    border-radius: 50rem;
    transition: border-color .15s;
    
    &-img {
        @include img-fluid;
        border-radius: inherit;
    }
}