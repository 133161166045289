.navbar-brand {
    &-logo {
        @include img-fluid;
        max-height: 2.5rem;
    }
}





.btn-cart {
    position: relative;
    font-size: 1.75rem;
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 2.75rem;
    height: 2.5rem;
    display: block;
    color: $gray-600;

    &:hover {
        color: $primary;
    }

    &-count {
        color: #fff;
        border-radius: 50px;
        background-color: $red;
        min-width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        top: 0;
        right: 0;
        font-size: 0.75rem;
        padding: 0 0.5rem;
        position: absolute;
        line-height: 1.75;
        border: 2px solid $white;
        display: inline-block;
    }
}