[data-spinner] .spinner {
    display: none;
}

[data-spinner="true"] {
    pointer-events: none;

   &.spinner-area {
        > *:not(.spinner) {
            opacity: .5;
        }
    }

    .spinner {
        display: inline-block;
    }
}


.spinner-area {
    position: relative;

    // &::after {
    //     content: "";
    //     position: absolute;
    //     z-index: 9;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-color: $white;
    //     opacity: .5;
    // }

    

    > .spinner {
        position: absolute;
        z-index: 10;
        top: calc(50% - (var(--#{$prefix}spinner-height) / 2));
        left: calc(50% - (var(--#{$prefix}spinner-width) / 2));
        left: 50%;
    }
}


//
// Rotating border
//

.spinner {
  display: inline-block;
  width: var(--#{$prefix}spinner-width);
  height: var(--#{$prefix}spinner-height);
  vertical-align: var(--#{$prefix}spinner-vertical-align);
  // stylelint-disable-next-line property-disallowed-list
  border-radius: 50%;
  animation: var(--#{$prefix}spinner-animation-speed) linear infinite var(--#{$prefix}spinner-animation-name);
}

// scss-docs-start spinner-border-keyframes
@keyframes spinner-border {
  to { transform: rotate(360deg) #{"/* rtl:ignore */"}; }
}
// scss-docs-end spinner-border-keyframes

.spinner {
  // scss-docs-start spinner-border-css-vars
  --#{$prefix}spinner-width: #{$spinner-width};
  --#{$prefix}spinner-height: #{$spinner-height};
  --#{$prefix}spinner-vertical-align: #{$spinner-vertical-align};
  --#{$prefix}spinner-border-width: #{$spinner-border-width};
  --#{$prefix}spinner-animation-speed: #{$spinner-animation-speed};
  --#{$prefix}spinner-animation-name: spinner-border;
  // scss-docs-end spinner-border-css-vars

  border: var(--#{$prefix}spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-sm {
  // scss-docs-start spinner-border-sm-css-vars
  --#{$prefix}spinner-width: #{$spinner-width-sm};
  --#{$prefix}spinner-height: #{$spinner-height-sm};
  --#{$prefix}spinner-border-width: #{$spinner-border-width-sm};
  // scss-docs-end spinner-border-sm-css-vars
}