// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav {
  // scss-docs-start nav-css-vars
  --#{$prefix}nav-link-padding-x: #{$nav-link-padding-x};
  --#{$prefix}nav-link-padding-y: #{$nav-link-padding-y};
  @include rfs($nav-link-font-size, --#{$prefix}nav-link-font-size);
  --#{$prefix}nav-link-font-weight: #{$nav-link-font-weight};
  --#{$prefix}nav-link-color: #{$nav-link-color};
  --#{$prefix}nav-link-hover-color: #{$nav-link-hover-color};
  --#{$prefix}nav-link-disabled-color: #{$nav-link-disabled-color};
  // scss-docs-end nav-css-vars

  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  // padding: var(--#{$prefix}nav-link-padding-y) var(--#{$prefix}nav-link-padding-x);
  @include font-size(var(--#{$prefix}nav-link-font-size));
  font-weight: var(--#{$prefix}nav-link-font-weight);
  color: var(--#{$prefix}nav-link-color);
  // text-decoration: if($link-decoration == none, null, none);
  background: none;
  border: 0;
  // @include transition($nav-link-transition);

  &:hover,
  &:focus {
    color: var(--#{$prefix}nav-link-hover-color);
    // text-decoration: if($link-hover-decoration == underline, none, null);
  }

  // &:focus-visible {
  //   outline: 0;
  //   box-shadow: $nav-link-focus-box-shadow;
  // }

  // Disabled state lightens text
  &.disabled {
    color: var(--#{$prefix}nav-link-disabled-color);
    pointer-events: none;
    cursor: default;
  }
}