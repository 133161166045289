.social {
    &-list {
        display: flex;
        @include list-unstyled;
    }

    &-link {
        color: $black !important;
        width: 2.5em;
        height: 2.5em;
        display: flex;
        background-color: $white;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        position: relative;

            &::after {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            content: '';
            box-sizing: content-box;
            padding: 3px;
            box-shadow: 0 0 0 2px $white;
            transition: all .15s;
            opacity: 0;
            transform: scale(.6);
        }

        &:hover {
            color: $pink !important;
            &::after { 
                opacity: 1;
                transform: scale(1);
            }

        }
    }
}