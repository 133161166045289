


body {
  overflow-x: hidden;
}


.skip-navigation {
  border: 0!important;
  height: 0.0625rem!important;
  overflow: hidden!important;
  padding: 2px !important;
  position: fixed !important;
  width: 0.0625rem!important;
  left: 50%;
  transform: translate(-50%, -100%);
  transition: transform 0.3s;
  z-index: $zindex-fixed + 1;
  font-weight: bold;

  &:focus {
    text-decoration: none;
    width: auto !important;
    height: auto !important;
    text-align: center;
    border: 0;
    transform: translate(-50%, 0%);
  }
}


.main-content {
  overflow: hidden;
  @include media-breakpoint-up('md') {
   
}
}

body::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zindex-modal-backdrop;
  background-color: rgba($color: $modal-backdrop-bg, $alpha: $modal-backdrop-opacity);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s linear, visibility 0.15s linear;
}

body.has-backdrop::after {
  visibility: visible;
  opacity: 1;
}