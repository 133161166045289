.svg-symbols,
.svg-logo {
    position: absolute;
    z-index: -1;
    left: -1px;
    top: -1px;
    width: 1px;
    height: 1px;
    visibility: hidden;
    overflow: hidden;
}


.icon {
    display: inline-block;
    line-height: 1;

    >.icon-svg,
    >svg {
        display: inline-block;
        fill: currentColor;
        line-height: 1em;
        height: 1em;
        width: 1em;
        vertical-align: -0.125em;
    }
}



.icon {    
    &-xs {
        font-size: .75rem
    }

    &-m {
        font-size: 1.25rem;
    }

    &-l {
        font-size: 1.5rem;
    }

    &-xl {
        font-size: 1.75rem;
    }

    &-2xl {
        font-size: 2rem;
    }
    &-3xl {
        font-size: 3rem;
    }

}

.icon-circle {
    width: 1em;
    height: 1em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex-shrink: 0;

    > .icon-svg, svg {
        max-height: 40%;
    }
    
        &.icon-sm {
            font-size: 1.25rem;
        }
    
        &.icon-md {
            font-size: 1.5rem;
    
            // >.icon-svg {
            //     // max-width: .875rem;
            //     max-height: .875rem;
            // }
        }
    
        &.icon-lg {
            font-size: 2rem;
    
            // >.icon-svg {
            //     // max-width: 1.125rem;
            //     max-height: 1.125rem;
            // }
        }
    
        &.icon-xl {
            font-size: 2.5rem;
    
            // >.icon-svg {
            //     // max-width: 1.125rem;
            //     max-height: 1.125rem;
            // }
        }
    
        &.icon-xxl {
            font-size: 3rem;
    
            // >.icon-svg {
            //     max-height: 1.5rem;
            // }
        }
        
        &.icon-3xl {
            font-size: 4rem;
        }
        &.icon-4xl {
            font-size: 5rem;
        }
    }



.icon-inline > svg {
    vertical-align: middle !important;
}
.icon-flip-x {
    transform: scaleX(-1);
}