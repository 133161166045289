

// general 


.rich-text,
.fr-view {

    .fr-class-highlighted {
        background-color: $mark-bg !important;
    }
    .fr-img-caption {
        margin-bottom: 1rem !important;
        img.fr-fic {
            margin-bottom: .5rem;
        }
    }

    img.fr-fic {
        margin-bottom: .75rem ; 
        &.fr-fil {
            float: left;
            margin-right: 1.25rem;
        }
        &.fr-fir {
            float: right;
            margin-left: 1.25rem;
        }
    }

    blockquote {
        font-size: $h4-font-size;
        color: $primary !important;
        margin: 1.5rem !important;
        padding-left: 1.5rem !important;
        border-left: 3px solid $primary !important;
        font-style: italic !important
    }

        table {
            --#{$prefix}table-color: #{$table-color};
            --#{$prefix}table-bg: #{$table-bg};
            --#{$prefix}table-border-color: #{$table-border-color};
            --#{$prefix}table-accent-bg: #{$table-accent-bg};
            --#{$prefix}table-striped-color: #{$table-striped-color};
            --#{$prefix}table-striped-bg: #{$table-striped-bg};
            --#{$prefix}table-active-color: #{$table-active-color};
            --#{$prefix}table-active-bg: #{$table-active-bg};
            --#{$prefix}table-hover-color: #{$table-hover-color};
            --#{$prefix}table-hover-bg: #{$table-hover-bg};

            width: 100%;
            margin-bottom: $spacer;
            color: var(--#{$prefix}table-color);
            vertical-align: $table-cell-vertical-align;
            border-color: var(--#{$prefix}table-border-color);
    
            // Target th & td
            // We need the child combinator to prevent styles leaking to nested tables which doesn't have a `.table` class.
            // We use the universal selectors here to simplify the selector (else we would need 6 different selectors).
            // Another advantage is that this generates less code and makes the selector less specific making it easier to override.
            // stylelint-disable-next-line selector-max-universal
            > :not(caption)>*>* {
                padding: $table-cell-padding-y $table-cell-padding-x;
                background-color: var(--#{$prefix}table-bg);
                border-bottom-width: $table-border-width;
                box-shadow: inset 0 0 0 9999px var(--#{$prefix}table-accent-bg);
            }
    
            >tbody {
                vertical-align: inherit;
            }
    
            >thead {
                vertical-align: bottom;
                th {
                    font-weight: bold;
                }
            }
        }

        .table-primary {
            thead th {
                color: $white;
                background-color: $primary;
            }
        }

.table-bordered {
    > :not(caption) > * {
      border-width: $table-border-width 0 !important;
  
      // stylelint-disable-next-line selector-max-universal
      > * {
        border-width: 0 $table-border-width !important;
      }
    }
  }
  
  .table-borderless {
    // stylelint-disable-next-line selector-max-universal
    > :not(caption) > * > * {
      border-width: 0;
    }
  
    > :not(:first-child) {
      border-top-width: 0;
    }
  }
  .table-striped {
    > tbody > tr:nth-of-type(#{$table-striped-order}) > * {
      --#{$prefix}table-accent-bg: var(--#{$prefix}table-striped-bg);
      color: var(--#{$prefix}table-striped-color); 
    }
  }
  .table-hover {
    > tbody > tr:hover > * {
      --#{$prefix}table-accent-bg: var(--#{$prefix}table-hover-bg);
      color: var(--#{$prefix}table-hover-color);
    }
  }
  

    .table-sm {
    // stylelint-disable-next-line selector-max-universal
    > :not(caption) > * > * {
        padding: $table-cell-padding-y-sm $table-cell-padding-x-sm;
    }
    }
}








// rich text 
.rich-text {

   &::after {
        display: block;
        clear: both;
        content: "";
    }

    img {
        @include img-fluid;
        margin-bottom: .75rem;
        &.align-start {
            float: left;
            margin-right: 1.25rem;
        }
        &.align-end {
            float: right;
            margin-left: 1.25rem;
        }
        &.align-center {
            display: block;
            margin-left: auto;
            margin-right: auto;
            clear: both;
        }
    }

    ol > li,
    ul > li {
        &:not(:last-child) {
            margin-bottom: .75rem;
        }
    }

    h1:not(:first-child),
    h2:not(:first-child),
    h3:not(:first-child),
    h4:not(:first-child),
    h5:not(:first-child),
    h6:not(:first-child) {
        margin-top: 1.75rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5
    h6 {
       margin-bottom: 1rem;
    }

   ol.steps {
    list-style: none;
    counter-reset: num;
     li {
        counter-increment: num;
        position: relative;
        padding-left: .5rem;
        &::before {
            content: counter(num) "";
        }
     }
   }

    ol.steps>li:before {
        background-color: $primary;
        border-radius: 50%;
        color: #fff;
        font-size: $small-font-size;
        height: 1.75em;
        left: -1.75em;
        line-height: 1.75em;
        position: absolute;
        text-align: center;
        width: 1.75em;
    }


}