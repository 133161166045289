.modal-book {
    .modal-content {
        margin-top: 4rem;
    }
     .btn-close {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    background-color: #fff;
    border-radius: 50%;
    top: -4rem;
    right: 0;
    opacity: 1;
    display: flex;
    justify-content: center;
}
.modal-text > p {
    margin-bottom: .5rem;
}
}