.form-order {
    .form-floating {
        .form-control, .form-select {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            padding-left: .25rem;
            padding-right: 0;
            &:focus ~ label {
                color: $primary;
            }
        }
        > label {
            padding-left: 0;
        }
    }
}

.form-sign-in,
.form-forgot-password {
    max-width: 400px;
}

.book-cover-preview {
    border: 2px dashed $gray-400;
    position: relative;
}

.book-cover-preview::after {
    content: 'Cover Preview';
    color: $gray-500;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
    z-index: -1;
}

.book-cover-preview[aria-hidden="true"] > img {
    display: none;
}


.form-select-emails  {
    min-height: 350px;
}